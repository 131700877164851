import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { schemaVerificaciones } from '../../constans/yupSchemas';
import { fetchEmpresasToDropdown } from '../../store/empresasDuck';
import { getListaColaboradoresByEmpresa } from '../../store/colaboradorDucks';
import { createCitatorio } from '../../store/citatorioDuck';
import { createVerificacion } from '../../store/verificacionesDuck';
import { Form, Input, FormGroup, Label, Col, CustomInput, Collapse, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import SweetAlert from '../ui/SweetAlet';
import CustomSelect from '../ui/CustomSelect';
import axios from '../../services/axios';
import { HeaderStyle } from "../../components/ui/styles/StyleNomina";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SaveButton,CancelButton } from '../ui/styles/styles';
import {
  faAngleDown,
  faAngleUp
} from '@fortawesome/pro-light-svg-icons';
import InputImage from '../ui/InputImage';
import {TextAreaUtils} from '../colaboradores/UtilitarioViews/styled';
import swal from "sweetalert2";

const api = process.env.REACT_APP_API_URL;

const AgregarVerificacion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [salario, setSalario] = useState({salarioDiario:''});
  
  const [err, setErr] = useState({});
  const [empresa, setEmpresa] = useState('');
  const {salarioDiario} = salario;
  const { empresas = [] } = useSelector((state) => state.empresas);
  const { listaColaboradores = [] } = useSelector((state) => state.colaborador);
  const { permisos = {} } = useSelector((state) => state.user)
  const [collapseDatosPersonales, setCollapseDatosPersonales] = useState(true);
  const [collapseDatosSolicitud, setCollapseDatosSolicitud] = useState(false);
  const [collapseDatosHistorialLaboral, setCollapseDatosHistorialLaboral] = useState(false);
  const [cleanImage, setcleanImage] = useState('');
  const [ verificacionPermiso, setVerificacionPermiso] = useState('none');
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const toggleDsatosPersonales = () => setCollapseDatosPersonales(!collapseDatosPersonales);
  const toggleDatosSolicitud = () => setCollapseDatosSolicitud(!collapseDatosSolicitud);
  const toggleDatosHistorialLaboral = () => setCollapseDatosHistorialLaboral(!collapseDatosHistorialLaboral);

  
  const { register, handleSubmit, errors, control, setValue, getValues } = useForm({
    resolver: yupResolver(schemaVerificaciones),
  });
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  
  const getEmpresas = useCallback(async () => {
    try {
      await dispatch(fetchEmpresasToDropdown());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);
  const onChange = async ({ target }) => {
    if (!target.value) return;
    const filter = empresas.filter(x =>x.value === target.value);
    setEmpresa(filter[0].label);
  };
  
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
    getEmpresas();
    register({ name: 'archivos' }, { required: false });
    if ("verificaciones" in permisos) {
      setVerificacionPermiso(permisos["verificaciones"]);
    }
  }, [getEmpresas, register,errors]);

  const onSubmit = async () => {
    const data = getValues();
    console.log(data);
    
    try {
      swal.showLoading();
      data.fechaNacimiento = parseInt(moment(data.fecha).format('x'));
      await dispatch(createVerificacion(data));
      await SweetAlert({
        icon: 'success',
        title: 'Verificacion agregada correctamente',
      });
      swal.showLoading();
      history.replace('/dashboard/recursos-humanos/verificaciones');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    <HeaderStyle style={{marginBottom:50}}>
      <h1>Nuevo Prospecto</h1>
      <h3>Agrega un nuevo prospecto</h3>
    </HeaderStyle>
    <div className="row col-sm-12" >
      <div 
        className='col-sm-12 border rounded d-flex justify-content-between align-items-center bg-light'
        style={{cursor:'pointer'}} onClick={toggleDsatosPersonales}  
      >
        <h3 style={{ marginBottom: '5px' }}>
          Datos personales
        </h3>
        <FontAwesomeIcon 
          icon={collapseDatosPersonales ? faAngleUp : faAngleDown}
          size='2x'
        />
      </div>
      <Collapse
        isOpen={collapseDatosPersonales}
        className='col-sm-12'
      >
        {/* <Form> */}
          <FormGroup row>
            <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
              Datos personales del prospectos
            </h5>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} for='foto'>
              Foto
            </Label>
            <Col sm={9}>
              <InputImage
                type='file'
                id='foto'
                name='foto'
                accept='image/jpg,image/jpeg,image/png'
                loadImageUrl={cleanImage}
                register={register}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Nombre*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='nombre'
                innerRef={register()}
                placeholder='Introduzca nombre'
              />
              {err.nombre && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Nombre es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Fecha Nacimiento*</Label>
            <Col sm={9}>
              <Input
                type='date'
                name='fechaNacimiento'
                innerRef={register()}
                placeholder='Introduzca la fecha de nacimiento'
              />
              {err.fechaNacimiento && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  La Fecha de nacimiento es requerida
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Lugar Nacimiento*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='lugarNacimiento'
                innerRef={register()}
                placeholder='Introduzca lugar de nacimiento'
              />
              {err.lugarNacimiento && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Lugar de Nacimiento es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Estado Civil*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='estadoCivil'
                innerRef={register()}
                placeholder='Introduzca Estado Civil'
              />
              {err.estadoCivil && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Estado Civil es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Domicilio*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='domicilio'
                innerRef={register()}
                placeholder='Introduzca Domicilio'
              />
              {err.domicilio && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Domicilio es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>NSS*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='nss'
                innerRef={register()}
                placeholder='Introduzca NSS'
              />
              {err.nss && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El NSS es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>CURP*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='curp'
                innerRef={register()}
                placeholder='Introduzca CURP'
              />
              {err.curp && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El CURP es requerido
                </span>
              )}
            </Col>
          </FormGroup>
        {/* </Form> */}
      </Collapse>
    </div>
    <div className="row col-sm-12 mt-2">
      <div 
        className='col-sm-12 border rounded d-flex justify-content-between align-items-center bg-light'
        style={{cursor:'pointer'}} onClick={toggleDatosSolicitud}  
      >
        <h3 style={{ marginBottom: '5px' }}>
          Datos solicitud
        </h3>
        <FontAwesomeIcon 
          icon={collapseDatosSolicitud ? faAngleUp : faAngleDown}
          size='2x'
        />
      </div>
      <Collapse
        isOpen={collapseDatosSolicitud}
        className='col-sm-12'
      >
        {/* <Form> */}
          <FormGroup row>
            <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
              Datos de la solicitud
            </h5>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Empresa*</Label>
            <Col sm={9}>
              <CustomSelect
                control={control}
                options={empresas}
                disabled={empresas.length > 0 ? false : true}
                type='select'
                name='empresa'
                innerRef={register()}
                onChange={onChange}
              />
              {err.empresa && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  La Empresa es requerida
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Area*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='area'
                innerRef={register()}
                placeholder='Introduzca Area'
              />
              {err.area && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Area es requerida
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Puesto*</Label>
            <Col sm={9}>
              <Input
                type='text'
                name='puesto'
                innerRef={register()}
                placeholder='Introduzca Puesto'
              />
              {err.fechaNacimiento && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El Puesto es requerido
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Comentarios</Label>
            <Col sm={9}>
              <TextAreaUtils
                name='comentarios'
                ref={register}
                placeholder='Comentarios relacionados con la situación del citatorio'
              />
            </Col>
          </FormGroup>
        {/* </Form> */}
      </Collapse>
    </div>
    <FormGroup check row className='mt-5'>
      <Col sm={{ size: 9, offset: 6 }}>
        <Label check>
          <Input type="checkbox" name="acceptTerms" ref={register} />
          {' '}Acepto los{' '}
          <a href="#" onClick={toggleModal}>
            Términos y Condiciones
          </a>
        </Label>
      </Col>
    </FormGroup>
    {/* Modal para los términos y condiciones */}
    <Modal isOpen={modalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Términos y Condiciones</ModalHeader>
      <ModalBody>
        Aquí puedes agregar tus términos y condiciones detallados.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggleModal}>
          Aceptar
        </Button>
      </ModalFooter>
    </Modal>
    <FormGroup row className="mt-5">
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.replace('/dashboard/recursos-humanos/verificaciones');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <SaveButton onClick={handleSubmit(onSubmit)}>Guardar</SaveButton>
        </Col>
    </FormGroup>
    </>
  );
};

export default AgregarVerificacion;
