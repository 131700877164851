import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;

const buildConfig = () => ({
  headers: {
    token: localStorage.getItem("pv2token") || "",
  }
});

const HttpService = {
  get: (url) => axios.get(baseUrl + url, buildConfig()),
  post: (url, data) => axios.post(baseUrl + url, data, buildConfig()),
  put: (url, data) => axios.post(baseUrl + url, data, buildConfig()),
  delete: (url) => axios.delete(baseUrl + url, buildConfig())
};

export default HttpService;

export const getAgregarParametrosExtra = parametrosExtra => {
  let consulta = "";

  for (const key in parametrosExtra) {
    const valor = parametrosExtra[key];
    consulta += `&${key}=${valor}`;
  }

  return consulta;
} 