import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { io } from 'socket.io-client';

import LogIn from './pages/LogIn/LogIn';
import Recuperar from './pages/LogIn/Recuperar';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';

import ProtectedRoutes from './routes/ProtectedRoutes/ProtectedRoutes';
import RegistroComponente from './pages/RegistroUsuario/RegistroComponente';
import { getNotificacionesAction } from './store/notificacionesDuck';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  //TODO: Cambiar dashboard por el nombre del sistema en rutas
 //problema en el router ya que no debe estar la logica de las rutas despues del switch (logica esta dentro de protected routes)
 const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();
 const token = localStorage.getItem('pv2token');
  const socket = io(process.env.REACT_APP_API_URL,{query: {token}}); // Cambia por tu URL backend

  useEffect(() => {
    // Ejecutar una acción al montarse el componente
    dispatch(getNotificacionesAction());
    
    
    // Escucha eventos de notificación
    socket.on('notification', (data) => {
      console.log('Notificación recibida:', data);
      toast(data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setMessages((prev) => [...prev, data]);
      dispatch(getNotificacionesAction())
    });

    // Cleanup al desmontar el componente
    return () => socket.disconnect();
  }, [socket, dispatch]);
  
  return (
    <>
      <ToastContainer />
      <Switch>
        <Route exact path='/'>
          <LogIn />
        </Route>
        <Route exact path='/recuperar-credenciales'>
          <Recuperar />
        </Route>
        <Route exact path='/registro-usuario' component={RegistroComponente}>
        </Route>
        
        <ProtectedRoutes path='/dashboard' component={Dashboard} messages={messages}/>
        
        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default App;
