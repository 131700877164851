const initialState = {
    loading: false,
    error: null,
    listaNotificaciones: []
  };
  
  export const FETCH_LISTA_NOTIFICACIONES = 'FETCH_LISTA_NOTIFICACIONES';
  export const FETCH_LISTA_NOTIFICACIONES_EXITO = 'FETCH_LISTA_NOTIFICACIONES_EXITO';
  export const FETCH_LISTA_NOTIFICACIONES_ERROR = 'FETCH_LISTA_NOTIFICACIONES_ERROR';

  export const SET_LISTA_NOTIFICACIONES_EXITO = 'SET_LISTA_NOTIFICACIONES_EXITO';
  

  
  export default function notificacionReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
      case FETCH_LISTA_NOTIFICACIONES:
        return { ...state, loading: true };
      case FETCH_LISTA_NOTIFICACIONES_EXITO:
        return { ...state, loading: false, listaNotificaciones: [...payload] };
      case FETCH_LISTA_NOTIFICACIONES_ERROR:
        return { ...state, error: payload, loading: false };
      case SET_LISTA_NOTIFICACIONES_EXITO:
        return { ...state, loading: false, listaNotificaciones: [...payload] };
      default:
        return state;
    }
  }
  
  export const setListaNotificacionesSucces = (payload) => ({
    type: SET_LISTA_NOTIFICACIONES_EXITO,
    payload,
  });
  
  export const getListaNotificacionesStart = () => ({
    type: FETCH_LISTA_NOTIFICACIONES,
  });
  
  export const getListaNotificacionesSucces = (payload) => ({
    type: FETCH_LISTA_NOTIFICACIONES_EXITO,
    payload,
  });
  
  export const getListaNotificacionesError = () => ({
    type: FETCH_LISTA_NOTIFICACIONES_ERROR,
  });
  
  
  export const getNotificacionesAction = () => async (
    dispatch,
    getState,
    { axios }
  ) => {
    dispatch(getListaNotificacionesStart());
    try {
      const { respuesta } = (
        await axios.get(`/notificaciones`, {
          headers: { token: localStorage.getItem('pv2token') },
        })
      ).data;
      console.log(respuesta);
      dispatch(setListaNotificacionesSucces( respuesta ));
    } catch (e) {
      console.log(e);
    }
  };


  