import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authorizedToken } from '../../store/userDuck';
import jwt_decode from "jwt-decode";

const ProtectedRoutes = ({ component: Component, auth, ...rest }) => {
  const dispatch = useDispatch();
  const [withAuth, setWithAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = hasSession();

      if(!token) {
        setWithAuth(false);
        setLoading(false);
        return;
      }
  
      try {
        //esto no deberia funcionar pero ya estaba asi hay que arreglarlo
        await dispatch(authorizedToken(token));
        setWithAuth(true);
      } catch (error) {
        console.log(error);
        setWithAuth(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const hasSession = () =>{
    const token = localStorage.getItem('pv2token');
    if(!token) return false;
    const { exp } = jwt_decode(token);
    const expirationTime = (exp * 1000) - 60000;
    if (Date.now() <= expirationTime) return token;
    else return false;
  }

  if(loading) return <div>Loading...</div>;

  return (
    <Route
      {...rest}
      render={(props) =>
        withAuth ? <Component {...props} {...rest}/> : <Redirect to='/' />
      }
    />
  );
};
export default ProtectedRoutes;
