import React, { useState } from 'react';
import { Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import colors from '../../../constans/colors';
import './SubSidebar.css';

const SubSideBar = ({ icon = faHeartBroken, title = 'envia titulo', routes = [], match, className}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  // console.log('routes', routes);
  

  const createMenu = () => {
    return routes.map(({ subMenuTitle, subRoutes, icon }, key) => {
      return (subRoutes
              ? subRoutes.map(({ path, name, icon }, key) => {
                return (
                  <li key={key + path}>
                    <NavLink
                      to={`${match.url}${path}`}
                      style={{ color: colors.submenuTextColor }}
                      className='links'
                    >
                    <FontAwesomeIcon
                      icon={icon}
                      style={{ fontSize: '1.0rem', marginRight: '0.5rem', color:'inherit' }} 
                    />
                      <span className='mt-5 mb-5 ml-3'>{name}</span>
                    </NavLink>
                  </li>
                );
              })
              : null)
    });
  };

  return (
    <div className='sub-menu-container'>
      <Button
        className={`sub-menu-btn ${className}`}
        onClick={toggle}
        style={{
          marginBottom: '1rem',
        }}
      >
        <FontAwesomeIcon
          color='inherit'
          icon={icon}
          style={{ fontSize: '1.2rem', marginRight: '0.5rem', color:'inherit' }}
        />

        <span>{title}</span>
      </Button>
      <Collapse isOpen={isOpen}>
        <ul>{routes.length > 0 ? createMenu() : 'Sin rutas'}</ul>
      </Collapse>
    </div>
  );
};

export default SubSideBar;
